<template>
  <form class="block__form has-tooltip">
    <!-- <div class="form-group">
        <input
            id="search"
            v-model="filtro.search"
            type="search"
            class="input-field"
        >
        </div> -->
    <!-- <div class="form-group">
      <div class="input-container">
        <i class="fa fa-search icon"></i>
        <input
          class="input-field"
          id="search"
          v-model="filtro.search"
          type="text"
          placeholder="Pesquisa"
        />
      </div>
    </div> -->

    <div class="form-group">
      <label
        >Origem
        <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"
      /></label>

      <div class="custom-control custom-radio custom-control-inline pl-3">
        <input
          id="questoes-banco"
          type="radio"
          v-model="filtro.origem"
          value="todas"
          name="origem"
          class="custom-control-input"
        />
        <label class="custom-control-label small" for="questoes-banco"
          >Todas</label
        >
      </div>

      <div class="custom-control custom-radio custom-control-inline pl-3">
        <input
          id="favoritas"
          type="radio"
          name="origem"
          value="favoritas"
          v-model="filtro.origem"
          class="custom-control-input"
        />
        <label class="custom-control-label small" for="favoritas"
          >Favoritas</label
        >
      </div>

      <div class="custom-control custom-radio custom-control-inline pl-3">
        <input
          id="criadas"
          type="radio"
          name="origem"
          value="minhas"
          v-model="filtro.origem"
          class="custom-control-input"
        />
        <label class="custom-control-label small" for="criadas"
          >Criadas por mim</label
        >
      </div>
    </div>

    <div class="form-group full-select">
      <label for="disciplina"
        >Componente Curricular
        <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"
      /></label>
      <select2
        id="disciplina"
        v-model="filtro.disciplinaSelecionada"
        :settings="{ multiple: false, placeholder: 'Selecione' }"
        :options="disciplinasDisponiveis"
        @change="onChangeDisciplina()"
      />
    </div>

    <div class="form-group full-select">
      <label for="ano"
        >Ano
        <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"
      /></label>
      <select2
        id="ano"
        v-model="filtro.anoSelecionado"
        :settings="{ multiple: false, placeholder: 'Selecione' }"
        :options="anosDisponiveis"
        @change="onChangeAno()"
      />
    </div>

    <div class="form-group full-select">
      <label for="assunto"
        >Habilidade
        <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"
      /></label>
      <select2
        id="assuntoFiltro"
        v-model="filtro.assuntosSelecionados"
        :settings="{ multiple: true, placeholder: 'Selecione' }"
        :options="assuntosDisponiveis"
      />
    </div>

    <div class="form-group">
      <label
        >Tipo
        <i class="help ml-1" data-toggle="tooltip" title="Some tooltip text!"
      /></label>
      <div class="custom-control custom-radio custom-control-inline pl-3">
        <input
          id="objetiva"
          type="radio"
          v-model="filtro.tipo"
          value="Objetiva"
          name="tipo"
          class="custom-control-input"
        />
        <label class="custom-control-label small" for="objetiva"
          >Objetiva</label
        >
      </div>
      <div class="custom-control custom-radio custom-control-inline pl-3">
        <input
          id="descritiva"
          type="radio"
          v-model="filtro.tipo"
          value="Descritiva"
          name="tipo"
          class="custom-control-input"
        />
        <label class="custom-control-label small" for="descritiva"
          >Descritiva</label
        >
      </div>
      <div class="custom-control custom-radio custom-control-inline pl-3">
        <input
          id="todas"
          type="radio"
          v-model="filtro.tipo"
          value="todas"
          name="tipo"
          class="custom-control-input"
        />
        <label class="custom-control-label small" for="todas">Todas</label>
      </div>
    </div>

    <div class="form-group">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-classic btn-block" @click.prevent="filtrar">
            <i class="fas fa-search" /> Filtrar
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Select2 from "v-select2-component";

export default {
  components: {
    Select2,
  },
  props: [
    "disciplinasDisponiveis",
    "anosDisponiveis",
    "assuntosDisponiveis",
    "filtro",
    "disciplinaSelecionada",
    "anoSelecionado",
  ],
  data() {
    return {
      anos: [],
    }
  },
  created() {
    this.$parent.loadQuestoes();
    this.$parent.loadDisciplinas();
    this.$parent.loadAnos();
    this.filtro.tipo = "";
    this.filtro.origem = "";
    this.filtro.disciplinaSelecionada = this.disciplinaSelecionada;
    this.filtro.anoSelecionado = this.anoSelecionado;
    if (this.disciplinaSelecionada || this.anoSelecionado) {
      this.filtrar();
    }
  },
  methods: {
    filtrar() {
      this.$parent.filtrar();
    },
    onChangeDisciplina() {
      console.log();
      this.$parent.loadAssuntos(this.filtro.disciplinaSelecionada, this.filtro.anoSelecionado);
    },
    onChangeAno() {
      this.$parent.changeAno(this.filtro.anoSelecionado);
      if(this.filtro.disciplinaSelecionada){
        this.$parent.loadAssuntos(this.filtro.disciplinaSelecionada, this.filtro.anoSelecionado);
      }
    },
    loadAnos() {
      this.$root.$emit("Spinner::show");

      this.$api.get("ano_ensinos").then((response) => {
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.anos.push(res);
          }
        });
        this.$root.$emit("Spinner::hide");
      });
    },
  },
};
</script>

<style>
.input-container {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  height: 35px;
  border: 1px solid #c4c4c4;
}

.icon {
  padding-top: 10px;
  color: black;
  min-width: 50px;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.btn-border {
  border: 1px solid #4da54f;
}
</style>